import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import AppNavbar from '../../components/AppNavbar';
import type { CompanyAccountComponent } from '../../types/account';
import { getCompanyStats } from '../../queries/account';
import ViewTitle from '../../components/ViewTitle';
import LoanStats from '../../features/account/LoanStats';
import PLRStats from '../../features/account/PlrStats';

export default function CompanyAccount(props:CompanyAccountComponent) {
  const { token, companyName } = props;
  const { statsData, statsDataLoading, statsDataError } = getCompanyStats(token);
  return (
    <AppNavbar title={companyName}>
      <>
        <Grid
          container
        >
          <ViewTitle
            title="Te invitamos a revisar el impacto que ha generado banca.me en tu empresa"
            xs={12}
            md={8}
            lg={8}
          />
        </Grid>
        {statsDataError && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
        {!statsDataError && statsDataLoading && <CircularProgress size={50} />}
        {!statsDataError && !statsDataLoading && statsData
          && (
          <>
            <LoanStats loanStats={statsData.loanStats} />
            <br />
            <PLRStats plrStats={statsData.plrStats} />
            <div style={{ height: '100px' }} />
          </>
          )}
      </>
    </AppNavbar>
  );
}
