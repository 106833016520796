import React from 'react';

const FAQContent = [
  {
    id: 1,
    title: '¿Por qué el estrés financiero de los trabajadores impacta en la productividad de tu empresa?',
    detail:
  <div>
    <div>
      El estrés financiero produce distracciones que llevan a una menor productividad.
      El 76% de los empleados estresados dicen que las preocupaciones financieras han tenido
      un impacto negativo en su productividad. El 25% de este grupo dice que el impacto ha sido
      grave o importante. El 55 % de los empleados estresados que se distraen
      con sus finanzas en el trabajo pasan más de 3 horas a la semana en el trabajo ocupándose
      de sus finanzas
      <sup>1</sup>

    </div>
    <div style={{ maxWidth: 300 }}>
      <hr />
    </div>
    <div style={{ fontSize: 12 }}>
      {' '}
      <sup>1</sup>
      Encuesta de bienestar financiero de empleados de PwC 2022
    </div>
  </div>,
  },
  {
    id: 2,
    title: '¿Cuáles son los beneficios que entrega banca.me a los colaboradores de las empresas?',
    detail:
  <div>
    <ol style={{ margin: 0, padding: '2.5%' }}>
      <li>
        Asesoría y educación financiera
      </li>
      <li>
        Créditos responsables acorde al nivel de deuda y capacidad de pago
      </li>
      <li>
        Re-financiamiento para unificar deudas, reduciendo hasta un 10% la cuota mensual
      </li>
    </ol>
  </div>,
  },
  {
    id: 3,
    title: '¿Cuáles son los beneficios que entrega banca.me a las empresas?',
    detail:
  <ol style={{ margin: 0, padding: '2.5%' }}>
    <li>
      <span style={{ fontWeight: 'bold' }}>Mayor retención:</span>
      {' '}
      Los colaboradores con estrés financiero son más propensos a buscar un nuevo trabajo
    </li>
    <li>
      <span style={{ fontWeight: 'bold' }}>Mejor salud mental:</span>
      {' '}
      Quienes sufren de estrés financiero tienen tres veces más probabilidades
      de sentir un gran impacto negativo debido a las preocupaciones por el dinero
    </li>
    <li>
      <span style={{ fontWeight: 'bold' }}>Incremento en productividad:</span>
      {' '}
      El estrés financiero tiene múltiples efectos en cadena
    </li>
  </ol>,
  },
  {
    id: 4,
    title: '¿Tiene algún costo?',
    detail:
  <div>
    No. ¡Un convenio con banca.me no tiene costo para tu empresa!
  </div>,
  },
  {
    id: 5,
    title: '¿Cómo afecta el proceso de remuneraciones de mi empresa?',
    detail:
  <div>
    Si tienes colaboradores de tu empresa con créditos BancaMe,
    nosotros te notificaremos sobre cualquier descuento que la persona
    encargada de remuneraciones deba realizar.
    El día 15 de cada mes enviaremos una nómina con el detalle.
  </div>,
  },
  {
    id: 6,
    title: '¿Existe algún riesgo financiero?',
    detail:
  <div>
    No. La deuda del colaborador es con banca.me por
    lo tanto tu empresa está exenta de riesgo financiero.
  </div>,
  },
  {
    id: 7,
    title: '¿Qué pasa si un empleado deja la empresa?',
    detail:
  <div>
    Tu empresa no está relacionada con la deuda del colaborador. Cuándo un empleado es desvinculado,
    banca.me es el responsable del seguimiento y futuros pagos del crédito.
  </div>,
  },
];

export default FAQContent;
