import React from 'react';
import {
  PieChart, Pie, Sector, Cell, ResponsiveContainer,
} from 'recharts';
import { useTheme } from '@mui/material';
import { useIsWidthDown } from '../../utils/widthHooks';

interface RenderActiveShapeInterface{
    cx: number,
    cy: number,
    midAngle: number,
    innerRadius: number,
    outerRadius: number,
    startAngle: number,
    endAngle: number,
    fill: string,
    payload: {id: number, name:string, value:number},
    percent:number,
    value: number,
}
// eslint-disable-next-line max-len
const renderActiveShape = (props: RenderActiveShapeInterface, isDownMd:boolean, isMoney:boolean, showPercentage: boolean) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 6) * cos;
  const sy = cy + (outerRadius + 6) * sin;
  const mx = cx + (outerRadius + 25) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * (15);
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4}
        outerRadius={outerRadius + 6}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      { isDownMd ? payload.name.split(' ').map((word, idx) => (
        <text
          key={`text_n_${word}`}
          x={ex + (cos >= 0 ? 1 : -1) * 5}
          y={ey + idx * 13 - 15}
          textAnchor={textAnchor}
          fill="#333"
          fontSize={14}
          fontWeight="bold"
        >
          {`${word}`}

        </text>
      )) : (
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {`${payload.name}`}

        </text>
      )}
      <text
        x={ex + (cos >= 0 ? 1 : -1) * (isDownMd ? 5 : 15)}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={13}
      >
        {isMoney ? `$ ${value.toLocaleString()}` : `${value.toLocaleString()}`}
      </text>
      {showPercentage
      && (
      <text
        x={ex + (cos >= 0 ? 1 : -1) * (isDownMd ? 8 : 15)}
        y={ey}
        dy={35}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={12}
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
      )}
    </g>
  );
};

interface CustomPieChartInterface {
  data:{id: string, name: string, value: number}[],
  isMoney?: boolean,
  showPercentage?: boolean
}

const defaultProps = {
  isMoney: true,
  showPercentage: true,
};

export default function CustomPieChart(props: CustomPieChartInterface) {
  const {
    data,
    isMoney = defaultProps.isMoney,
    showPercentage = defaultProps.showPercentage,
  } = props;
  const theme = useTheme();
  const isDownMd = useIsWidthDown('md');
  const [activeIndex, setActiveIndex] = React.useState(1);
  const onPieEnter = React.useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );
  const COLORS = [theme.palette.primary.main,
    theme.palette.tertiary.main,
    theme.palette.secondary.main];
  return (
    <ResponsiveContainer width="100%" height={250}>
      <PieChart style={{ margin: 'auto' }}>
        <Pie
          activeIndex={isDownMd ? activeIndex : [0, 1, 2]}
          activeShape={(prop) => renderActiveShape(prop, isDownMd, isMoney, showPercentage)}
          data={data}
          cx="50%"
          cy={150}
          innerRadius={isDownMd ? 30 : 40}
          outerRadius={isDownMd ? 60 : 70}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
          startAngle={-180}
          endAngle={-360}
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${entry.id}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

CustomPieChart.defaultProps = defaultProps;
