import React, { ReactNode } from 'react';
import body from '../../assets/products/background.svg';
import logo from '../../assets/bancame_logo_dark.svg';

const headerTitle = (type: string|null) => {
  switch (type) {
    case 'enterprises':
      return (
        <>
          <h4 style={{ marginBottom: 0 }}>¡Queremos que conozcas cómo están tus trabajadores! </h4>
          <h4 style={{ margin: '10px auto' }}>
            Por favor revisa los
            <span style={{ color: '#F44C7F' }}> dos productos que estamos evaluando </span>
            para entregarte un mejor servicio
          </h4>
        </>
      );
    case 'workers':
      return (
        <h4>
          ¡Dinos qué producto
          <span style={{ color: '#35aac5' }}> te gusta más </span>
          para desarrollarlo!
        </h4>
      );
    default:
      return <div />;
  }
};

interface HeaderInterface {
  type: string,
  children: ReactNode
}

function Header(props:HeaderInterface) {
  const { type, children } = props;
  return (
    <>
      <img src={body} alt="Background" className="products_background" />
      <div className="products_body">
        <div>

          <object data={logo} className="mainLogo"> </object>
          <h5 style={{ margin: 0 }}>Empresas</h5>

          {headerTitle(type)}
          {children}

        </div>
      </div>

    </>
  );
}

export default Header;
