import React from 'react';
import {
  CircularProgress,
  Fade,
  Typography,
} from '@mui/material';
import simulationStyles from '../../styles';
import isotipoDark from '../../../../assets/isotipo_dark_secondary.svg';

interface LoadingDataInterface{
  handleNext: ()=> void,
  handleEnableLoading: (state: boolean) => void,
}

function LoadingDataForm(props: LoadingDataInterface) {
  const {
    handleNext,
    handleEnableLoading,
  } = props;
  const classes = simulationStyles();
  const [loadingMsg, setLoadingMsg] = React.useState('Creando un diagnóstico...');
  React.useEffect(() => {
    setTimeout(() => setLoadingMsg('Calculando el ahorro de los trabajadores...'), 2000);
    setTimeout(() => setLoadingMsg('Calculando el ahorro en la empresa...'), 4000);
    setTimeout(() => setLoadingMsg('Estamos preparando los resultados...'), 6000);
    setTimeout(() => {
      handleNext();
      handleEnableLoading(false);
    }, 7000);
  }, []);
  return (
    <Fade
      in
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ timeout: 2000 }}
    >
      <div style={{ textAlign: 'center' }}>
        <div>
          <img
            src={isotipoDark}
            className={classes.loadingAvatar}
            alt="LoadingAvatar"
          />
        </div>
        <br />
        <CircularProgress size={30} />
        <Typography
          align="center"
          variant="h6"
        >
          {loadingMsg}
        </Typography>
      </div>
    </Fade>
  );
}

export default LoadingDataForm;
