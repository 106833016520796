import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const drawerWidth = 150;
const appBarHeight = 90;
const appBarMobileHeight = 70;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    height: appBarHeight,
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      height: appBarMobileHeight,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto',
      width: '100%',
    },
  },
  toolbar: {
    height: appBarHeight,
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      height: appBarMobileHeight,
    },
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 10,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    marginTop: appBarHeight,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      marginTop: appBarMobileHeight,
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(15) + 1,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  subtoolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'left',
      marginLeft: 20,
    },
    // necessary for content to be below app bar
  },
  content: {
    marginTop: appBarHeight + 10,
    width: '100%',
    overflowY: 'auto',
    maxHeight: window.screen.availHeight - (appBarHeight + 10),
    [theme.breakpoints.down('md')]: {
      marginTop: appBarMobileHeight + 10,
      maxHeight: window.screen.availHeight - (appBarMobileHeight + 10),
    },
  },
  logo_img: {
    width: 150,
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      width: 100,
      marginTop: 7,
    },
  },
}));

export const sx = (theme: Theme) => ({
  listItemButton: {
    borderRadius: '10px 0 0 10px',
    marginBottom: 0.5,
    marginLeft: 2,
    textIndent: -10,
    transition: '1s',
    fontSize: '18px',
    fontWeight: 'bold',
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center',
    color: theme.palette.white.main,
    [theme.breakpoints.down('lg')]: {
      textAlign: 'left',
      textIndent: 0,
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      color: theme.palette.tertiary.main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'white',
    },
    ':hover': {
      color: theme.palette.tertiary.main,
      backgroundColor: 'white',
    },
    '&.Mui-selected:hover': {
      color: theme.palette.tertiary.main,
    },

  },
});

export default useStyles;
