import React from 'react';
import {
  useLocation,
} from 'react-router-dom';
import logo from '../../../assets/bancame_logo_dark.svg';
import body from '../../../assets/generate-report/00_login/body_0.svg';
import JWTDecode from '../../../utils/jwtDecode';

const loginSwitch = (state:string|boolean|null) => {
  switch (state) {
    case true:
      return (
        <div key="success">
          <div style={{ fontSize: '25px', margin: '15px' }}>✔️</div>
          <h5 style={{ margin: 3 }}>¡Enlace correcto!</h5>
          <h6 style={{ margin: 3 }}>Accediendo...</h6>
        </div>
      );
    case false:
      return (
        <div key="failed">
          <div style={{ fontSize: '25px', margin: '15px' }}>❌</div>
          <h5 style={{ margin: 3 }}>¡Enlace erróneo!</h5>
          <h6 style={{ margin: 3 }}>Redirigiendo al inicio...</h6>
        </div>
      );
    case 'expired':
      return (
        <div key="expired">
          <div style={{ fontSize: '25px', margin: '15px' }}>⌛</div>
          <h5 style={{ margin: 3 }}>¡Enlace expirado!</h5>
          <h6 style={{ margin: 3 }}>Por favor contáctate con el servicio al cliente de Banca.Me</h6>
        </div>
      );
    default:
      return (
        <div key="loading">
          <div className="loader" />
          <h5 style={{ margin: 3 }}>Comprobando enlace</h5>
          <h6 style={{ margin: 3 }}>Un momento por favor...</h6>
        </div>
      );
  }
};

const useQuery = () => new URLSearchParams(useLocation().search);

interface LogInInterface{
  handleLogin: (token: string) => void,
}

interface payloadInterface{
  firstName:string,
  email: string,
  idType: string,
  idNumber: string,
  userId: string,
  tokenIntention: string,
  role: string,
  exp: number
}

function LogIn(props: LogInInterface) {
  const { handleLogin } = props;
  const query = useQuery();
  const token = query.get('token');
  let payload: payloadInterface|null = null;
  try {
    payload = JWTDecode(token);
  } catch (e) {
    payload = null;
  }
  const [loginState, setLoginState] = React.useState<string|boolean|null>(null);
  React.useEffect(() => {
    document.body.style.backgroundColor = '#35aac5';
    if (!payload || payload.tokenIntention !== 'hrCredentials') {
      return setLoginState(false);
    }
    const now = new Date().getTime();
    const tokenExpDate = new Date(payload.exp * 1000).getTime();
    if (+now > +tokenExpDate) {
      return setLoginState('expired');
    }
    return setLoginState(true);
  }, [payload, handleLogin, token]);

  React.useEffect(() => {
    if (loginState === true) {
      handleLogin(token as string);
    } else if (loginState === false) {
      setTimeout(() => {
        window.location.href = 'https://banca.me';
      }, 3000);
    }
  }, [loginState, token, handleLogin]);

  return (
    <>
      <img src={body} alt="Background" className="generateReportbackground" />
      <div style={{
        textAlign: 'center',
        display: 'flex',
        height: '100%',
        placeContent: 'center',
        alignItems: 'center',
        color: '#262D40',
      }}
      >
        <div>
          <object data={logo} width="130px" height="130px"> </object>
          <h2>Índice Banca.Me</h2>
          {loginSwitch(loginState)}
        </div>
      </div>
    </>
  );
}

export default LogIn;
