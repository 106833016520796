import React from 'react';
import {
  Drawer,
  Container,
  AppBar,
  Toolbar,
  List,
  Tooltip,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';

import bancameLogo from '../../assets/bancame_logo_dark.svg';
import useStyles, { sx } from './styles';
import buttonsArray from './helpers';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
const handleOpenWhatsApp = () => {
  window.open('https://api.whatsapp.com/send/?phone=56920711980&text=Hola.+Tengo+una+pregunta+con+respecto+al+portal+Empresas');
};

type AppBarDrawerProps = {
  children: React.ReactElement<any, any>,
  title?: string,
  hideNavigation?: boolean
}
const defaultProps = {
  title: '',
  hideNavigation: false,
};

function AppNavbar(props: AppBarDrawerProps) {
  const {
    children,
    title,
    hideNavigation,
  } = props;
  const theme = useTheme();
  const width = useWidth();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen((o) => !o);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const buttonsSwitch = (role) => {
    switch (role) {
      case 'rrhh':
        return buttonsArray;
      default:
        return [];
    }
  };
  const drawerButtonsList = (
    <List>
      {buttonsSwitch('rrhh').map((butt) => (
        <ListItemButton
          key={butt.key}
          onClick={() => { history.push(butt.key); butt.gtm(); handleDrawerClose(); }}
          selected={[butt.key].includes(window.location.pathname)}
          sx={sx(theme).listItemButton}
        >
          <ListItemText
            primary={butt.label}
            disableTypography
          />
        </ListItemButton>
      ))}
    </List>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          {!['xl', 'lg'].includes(width)
            && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <Tooltip title="Abrir menú">
                <MenuIcon />
              </Tooltip>
            </IconButton>
            )}
          <div style={{
            display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <img src={bancameLogo} alt="BancaMeLogo" className={classes.logo_img} aria-hidden="true" />
              </div>
              <Typography
                variant="h5"
                fontWeight="bold"
                paddingLeft={{ xs: 3, lg: 12 }}
                display={{ xs: 'none', sm: 'flex' }}
              >
                {title.toUpperCase()}

              </Typography>
            </div>
            <Button variant="contained" onClick={handleOpenWhatsApp}>Contactar a un agente</Button>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={['xl', 'lg'].includes(width) ? 'permanent' : 'temporary'}
        open={open}
        onClose={handleDrawerClose}
        className={classes.drawerOpen}
        classes={{
          paper: ['xl', 'lg'].includes(width) ? classes.drawerOpen : classes.drawerPaper,
        }}
        anchor="left"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '0 30px 0 0',
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.subtoolbar}>
          <IconButton
            style={{
              backgroundColor: theme.palette.tertiary.main,
              borderRadius: '50px',
              padding: 5,
              width: 40,
              height: 40,
            }}
          >

            <ArrowBackIcon />
          </IconButton>
        </div>
        {!hideNavigation && drawerButtonsList}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.subtoolbar} />
        <Container maxWidth={width === 'xl' ? 'lg' : width}>
          {children}
        </Container>
      </main>
    </div>
  );
}

export default AppNavbar;

AppNavbar.defaultProps = defaultProps;
