import React from 'react';
import body6 from '../../../assets/company-report/08_good-to-know/body_6.svg';
import ellipse6 from '../../../assets/company-report/08_good-to-know/ellipse_6.svg';
import logo from '../../../assets/bancame_logo_dark.svg';
import img6 from '../../../assets/company-report/08_good-to-know/img_6.svg';
import './styles.css';
import StatsPieChart from '../../../components/StatsPieChart';
import { RenderedCompanyReport } from '../../../utils/interfaces/company-report';

interface CRDataInterface {
  reportData: RenderedCompanyReport
}

function CompanyReportGoodToKnow(props: CRDataInterface) {
  const { reportData } = props;
  React.useEffect(() => {
    document.body.style.backgroundColor = '#FCCFDD';
  }, []);

  let totalWorkers = 1;
  let over15 = 0;
  let canInvest = 0;
  if (reportData.companyData) {
    totalWorkers = reportData.companyData.totalToIncome.total;
    canInvest = reportData.companyData.canInvest;
    over15 = reportData.companyData['over15%'];
  }
  const data0 = [
    { name: 'over15', value: +over15 },
    { name: 'rest', value: +totalWorkers - over15 },
  ];
  const data1 = [
    { name: 'canInvest', value: +canInvest },
    { name: 'rest', value: +totalWorkers - canInvest },
  ];
  const dataObject: {[key:number]: any} = {
    0: {
      title: 'Descuentos sobre el 15%',
      data: data0,
      description:
  <>
    A
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {over15}
      {' '}
      colaboradores
      {' '}
    </span>
    {' '}
    se
    les descuenta sore
    {' '}
    <span style={{ fontWeight: 550 }}> lo estipulado por la Dirección del Trabajo </span>

  </>,
    },
    1: {
      title: 'Capacidad de inversión',
      data: data1,
      description:
  <>
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {canInvest}
      {' '}
      de tus colaboradores
      {' '}
    </span>
    {' '}
    poseen
    capacidad
    {' '}
    <span style={{ fontWeight: 550 }}> para invertir su dinero </span>

  </>,
    },
  };

  const [selected, setSelected] = React.useState(0);
  return (
    <div>
      <img src={body6} alt="sixthBackground" className="sixth_background" />
      <img src={ellipse6} alt="sixthBackground" className="ellipse_6" />
      <div className="sixth_body">
        <div className="sixth_header">
          <object data={logo} width="70px" height="70px"> </object>
          <h1 className="title_6">Good to know</h1>
        </div>
        <div className="img_6_container">
          <object data={img6} className="img_6"> </object>
        </div>
        <div className="sixth_content">
          <div className="info_container">
            <div className="chart_container">
              <StatsPieChart
                chartData={dataObject[selected].data}
                size="normal"
              />
            </div>
            <div className="info_subContainer">
              <h2 className="subtitle_2">
                {dataObject[selected].title}
              </h2>
              <h3 className="description_2">
                {dataObject[selected].description}
              </h3>
              <div className="data_buttons">
                <div className="tooltip" onClick={() => setSelected(0)} aria-hidden="true">
                  <i style={{ color: selected === 0 ? '#F44C7F' : undefined }} className="material-icons">assignment_late</i>
                  <span className="tooltiptext">{dataObject[0].title}</span>
                  <span className="badge badge-success pull-right"><object data={logo} width="14px" height="10px"> </object></span>
                </div>
                <div className="tooltip">
                  <i style={{ color: selected === 1 ? '#F44C7F' : undefined }} onClick={() => setSelected(1)} className="material-icons" aria-hidden="true">assessment</i>
                  <span className="tooltiptext">{dataObject[1].title}</span>
                  <span className="badge badge-success pull-right"><object data={logo} width="14px" height="10px"> </object></span>
                </div>
              </div>

            </div>
            <div />
            <div>
              <div className="upper_arrow"><i style={{ fontSize: 'medium' }} className="material-icons">keyboard_arrow_up</i></div>
              <div className="select_description">
                <div>
                  Click aquí para seleccionar un coeficiente
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CompanyReportGoodToKnow;
