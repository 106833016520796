import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundImage from '../../assets/background_isotipo_dark.svg';

const simulationStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    position: 'absolute',
    textAlign: 'left',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.darkSecondary,
    padding: theme.spacing(0, 5),
    paddingBottom: theme.spacing(5),
    backgroundSize: '10%',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: 'left',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  navBar: {
    display: 'grid',
    width: '100%',
    alignItems: 'center',
    height: '15%',
  },
  stepperContainer: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  logo_img: {
    width: 150,
    marginLeft: 7,
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
  sub_logo_img: {
    cursor: 'pointer',
    width: 45,
    margin: 10,
    [theme.breakpoints.down('md')]: {
      width: 30,
      position: 'relative',
    },
  },
  backButton: {
    margin: 'auto',
  },
  leftGrid: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '10px 0px 0px 10px',
    height: '70%',
  },
  infographicsContainer: {
    display: 'grid',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: '10%',
    backgroundImage: `url(${BackgroundImage})`,
    [theme.breakpoints.down('md')]: {
      backgroundSize: '0',
    },
  },
  rightGrid: {
    backgroundColor: theme.palette.background.default,
    height: '70%',
    display: 'grid',
    alignItems: 'center',
    padding: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      borderRadius: '10px',
      padding: theme.spacing(5),
      height: 570,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  selectOptions: {
    color: 'red',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 0),
    width: '60%',
    minHeight: '70vh',
    rowGap: 20,
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing('2.5%', 0),
    },
  },
  formHelperText: {
    [theme.breakpoints.up('sm')]: {
      height: 0,
      margin: 0,
    },
  },
  loadingAvatar: {
    width: 70,
    marginTop: 0,
    animation: `$myEffect 3000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
    [theme.breakpoints.down('sm')]: {
      width: 70,
      marginTop: 0,
    },
  },
  '@keyframes myEffect': {
    '0%': { transform: 'translate(0,  0px)' },
    '50%': { transform: ' translate(0, 7px)' },
    '100%': { transform: ' translate(0, -0px)' },
  },
  loadingCircle: {
    width: 300,
    animation: 'spin 6s infinite ease-in-out',
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  },
  detailGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    justifyItems: 'center',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
      columnGap: 0,
      rowGap: 10,
      width: '100%',
    },
  },
  contactFormContainer: {
    minHeight: '95vh',
    borderRadius: '10px',
    marginTop: '5%',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      backgroundSize: 0,
      padding: 10,
    },
  },
  submitButton: {
    maxWidth: theme.spacing(40),
    marginBottom: 0,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textInput: {
    margin: '10px auto',
    width: '50%',
  },
  helperText: {
    fontSize: 'small',
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: '0 2%',
    height: 0,
  },
  paper: {
    margin: theme.spacing(2, '5%'),
    padding: theme.spacing('5%', '5%'),
    borderRadius: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, '2%'),
    },
  },

  resultsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    rowGap: 15,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  resultCardsContainer: {
    backgroundColor: theme.palette.background.defaultSecondary,
    borderRadius: '0px 0px 10px 10px',
    paddingBottom: 15,
  },
  resultsCard: {
    display: 'grid',
    borderRadius: '10px',
    textAlign: 'center',
    width: '95%',
    padding: '8%',
    height: '200px',
    margin: 'auto 10%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginBottom: '10%',
    },
  },
  resultsTitle: {
    textAlign: 'center',
    borderRadius: '10px 10px 0px 0px',
    width: '100%',
    padding: theme.spacing(2, 3),
  },
  dot: {
    height: 18,
    width: 18,
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  resultsDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: 20,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
  },
  dialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        marginLeft: '2.5%',
        marginRight: '2.5%',
      },
    },
  },
  floatingButton: {
    position: 'fixed',
    float: 'right',
    zIndex: 1,
    bottom: 10,
    right: 10,
    borderRadius: '50%',
    height: 50,
    width: 50,
    padding: 10,
    margin: 10,
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      boxShadow: `0 0 5px 1px ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('sm')]: {
      height: 38,
      width: 38,
      padding: 5,
      margin: 0,
      marginBottom: 30,
    },
  },
  buttonsContainer: {
    margin: '2% auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 30,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      rowGap: 30,
    },
  },
}));

export default simulationStyles;
