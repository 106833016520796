import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from '@mui/material';
import useStyles from './styles';

type CustomBarChartProps = {
  data: {name: string, Cantidad: number}[],
  barColor?: 'primary' | 'alternativePrimary'
}
function XCustomizedAxisTick({ x, y, payload }: {
  x: number,
  y: number,
  payload: { name: string, value: number }
}) {
  const ValueToStr = payload.value.toString();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        style={{ fontSize: '15px' }}
      >
        {ValueToStr.charAt(0).toUpperCase() + ValueToStr.slice(1, ValueToStr.length)}
      </text>
    </g>
  );
}

function CustomTooltip({ active, payload, label }:
  { active: boolean, payload: { name: string, value: number }[], label: string }) {
  const theme = useTheme();
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customToolTip}>
        <div className={classes.customToolTipLabel}>
          {label.charAt(0).toUpperCase() + label.slice(1, label.length)}

        </div>
        {payload.map((PayloadValue) => (
          <div
            key={PayloadValue.name}
            style={{
              display: 'grid', gridAutoFlow: 'column', padding: '5px', alignItems: 'center',
            }}
          >
            <div style={{ color: theme.palette.tertiary.main, width: '200px' }}>{`${PayloadValue.name} :`}</div>
            <div style={{ color: theme.palette.tertiary.main, justifySelf: 'right' }}>{`${PayloadValue.value.toLocaleString()}`}</div>
          </div>
        ))}

      </div>
    );
  }
  return null;
}

export default function CustomBarChart(props: CustomBarChartProps) {
  const { data, barColor } = props;
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        width={500}
        height={250}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: -25,
          bottom: 5,
        }}
        barSize={15}
      >
        <XAxis
          dataKey="name"
          scale="point"
          height={100}
          padding={{ left: 10, right: 10 }}
          // @ts-ignore
          tick={<XCustomizedAxisTick />}
        />
        <YAxis allowDecimals={false} />
        <Tooltip
        // @ts-ignore
          content={<CustomTooltip />}
        />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="Cantidad" fill={barColor === 'primary' ? theme.palette.primary.main : theme.palette.tertiary.main} />
      </BarChart>
    </ResponsiveContainer>
  );
}

CustomBarChart.defaultProps = {
  barColor: 'primary',
};
