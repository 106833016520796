import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/background_isotipo_light.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: '5%',
    backgroundColor: theme.palette.background.defaultSecondary,
    minHeight: '100%',
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: '15%',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: '2%',
    },
  },
  logo: {
    width: 200,
  },
  notFound: {
    width: 250,
  },
  content: {
    display: 'grid',
    placeItems: 'center',
  },
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 15,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    paddingInline: '5%',
    paddingBlock: '2.5%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  img: {
    justifySelf: 'right',
  },
  text: {
    justifySelf: 'right',
    textAlign: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      justifySelf: 'center',
    },
  },
}));

export default useStyles;
