import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import AppNavbar from '../../../components/AppNavbar';
import type { CompanyAccountComponent } from '../../../types/account';
import { getCompanyInvoices } from '../../../queries/account';
import InvoiceDataTable from '../../../features/account/InvoiceDataTable';
import ViewTitle from '../../../components/ViewTitle';

export default function CompanyAccountInvoices(props: CompanyAccountComponent) {
  const { token, companyName } = props;
  const { invoicesData, invoicesDataLoading, invoicesDataError } = getCompanyInvoices(token);
  return (
    <AppNavbar title={companyName}>
      <>
        <Grid
          container
        >
          <ViewTitle
            title="Historial de pagos y pagos pendientes"
            xs={8}
            md={8}
            lg={4}
          />

        </Grid>
        {invoicesDataError && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
        {!invoicesDataError && invoicesDataLoading && <CircularProgress size={50} />}
        {!invoicesDataError && !invoicesDataLoading && invoicesData
          && (
          <InvoiceDataTable
            companyInvoices={invoicesData}
          />
          )}
        <div style={{ marginBottom: '100px' }} />
      </>
    </AppNavbar>
  );
}
