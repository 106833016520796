import React from 'react';
import { Formik, Form } from 'formik';
import {
  Checkbox,
  FormHelperText,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import SelectPlaceholder from '../../../../components/SelectPlaceholder';
import { FormBaseInterface } from '../../../../utils/interfaces/simulation';
import simulationStyles from '../../styles';
import {
  nWorkers,
  financialServices,
  companyField,
} from '../formLogic';
import { companyDataValidationSchema } from '../validationSchemas';
import fetcher from '../../../../utils/fetcher';
import useFBPixeleventTracker from '../../../../utils/useFBPixelEventTracker';

function CompanyDataForm(props: FormBaseInterface) {
  const {
    formRef,
    formSchema,
    handleCurrentData,
    handleNext,
    handleEnableLoading,
  } = props;
  const classes = simulationStyles();
  React.useEffect(() => handleEnableLoading(false), []);
  const [error, setError] = React.useState(false);
  useFBPixeleventTracker('Simulador empresas', { event: 'Presentación simulador empresas' });
  return (
    <Formik
      innerRef={formRef}
      validationSchema={companyDataValidationSchema}
      initialValues={formSchema}
      onSubmit={async (values) => {
        handleEnableLoading(true);
        const financialEntity = values.financialEntity === 'DEFAULT' ? undefined : values.financialEntity;
        try {
          const benefits = await fetcher(
            `${process.env.REACT_APP_API_URL}/company/simulate-benefits`,
            'POST',
            {
              economicActivity: values.economicActivity,
              financialInstitution: financialEntity,
              nWorkers: +values.nWorkers,
            },
            null,
          );
          handleCurrentData({ ...values, simulation: benefits.data });
          handleEnableLoading(false);
          window.dataLayer.push({
            event: 'Proceso simulación',
            paso: 'Formulario de empresas',
            tamano: nWorkers[+values.nWorkers],
            rubro: companyField[values.economicActivity],
            convenio_empresa: financialServices[values.financialEntity],
          });
          return handleNext();
        } catch (e) {
          return setError(true);
        }
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        touched,
        errors,
      }) => (
        <>
          <Typography variant="h5" align="left" gutterBottom style={{ fontWeight: 'bold' }}>
            Datos de la empresa
          </Typography>
          <Typography align="left" gutterBottom>
            Completa el siguiente formulario
          </Typography>
          <Form className={classes.form} style={{ minHeight: 0 }}>
            <FormControl error={touched.nWorkers && Boolean(errors.nWorkers)}>
              <Typography align="left" color="secondary" gutterBottom>
                Tamaño de la empresa
              </Typography>
              <Select
                id="nWorkers"
                name="nWorkers"
                displayEmpty
                value={values.nWorkers}
                onChange={handleChange}
                onBlur={handleBlur}
                renderValue={
                values.nWorkers !== '' ? undefined : () => <SelectPlaceholder>Seleccionar...</SelectPlaceholder>
              }
              >
                {Object.keys(nWorkers).map((CF) => (
                  <MenuItem key={CF} value={CF}>
                    {nWorkers[CF]}
                  </MenuItem>
                ))}

              </Select>
              <FormHelperText className={classes.formHelperText}>
                {touched.nWorkers ? errors.nWorkers : ''}
              </FormHelperText>
            </FormControl>
            <FormControl error={touched.economicActivity && Boolean(errors.economicActivity)}>
              <Typography
                align="left"
                variant="body1"
                color="secondary"
                gutterBottom
              >
                Rubro
              </Typography>
              <Select
                id="economicActivity"
                name="economicActivity"
                displayEmpty
                value={values.economicActivity}
                onChange={handleChange}
                onBlur={handleBlur}
                renderValue={
                values.economicActivity !== '' ? undefined : () => <SelectPlaceholder>Seleccionar...</SelectPlaceholder>
              }
              >
                {Object.keys(companyField).map((CF) => (
                  <MenuItem key={CF} value={CF}>
                    {companyField[CF]}
                  </MenuItem>
                ))}

              </Select>
              <FormHelperText className={classes.formHelperText}>
                {touched.economicActivity ? errors.economicActivity : ''}
              </FormHelperText>
              <FormControlLabel
                control={(
                  <Checkbox
                    size="small"
                    checked={values.economicActivity === 'DEFAULT'}
                    onClick={() => (values.economicActivity === 'DEFAULT'
                      ? setFieldValue('economicActivity', '')
                      : setFieldValue('economicActivity', 'DEFAULT'))}
                  />
)}
                label={(
                  <Typography align="left" display="inline" variant="caption">
                    El rubro de mi empresa
                    {' '}
                    <Typography
                      variant="caption"
                      display="inline"
                      style={{ fontWeight: 'bold' }}
                    >
                      no aparece en la lista

                    </Typography>
                  </Typography>
)}
              />
            </FormControl>
            <FormControl error={touched.financialEntity && Boolean(errors.financialEntity)}>
              <Typography
                variant="body1"
                gutterBottom
                align="left"
                color="secondary"
              >
                ¿Qué convenios tiene tu empresa?
              </Typography>

              <Select
                id="financialEntity"
                name="financialEntity"
                displayEmpty
                value={values.financialEntity}
                onChange={handleChange}
                onBlur={handleBlur}
                renderValue={
                values.financialEntity !== '' ? undefined : () => <SelectPlaceholder>Seleccionar...</SelectPlaceholder>
              }
              >
                {Object.keys(financialServices).map((FS) => (
                  <MenuItem key={FS} value={FS} color="secondary">
                    {financialServices[FS]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.formHelperText}>
                {touched.financialEntity ? errors.financialEntity : ''}
              </FormHelperText>
              <FormControlLabel
                control={(
                  <Checkbox
                    size="small"
                    checked={values.financialEntity === 'DEFAULT'}
                    onClick={() => (values.financialEntity === 'DEFAULT'
                      ? setFieldValue('financialEntity', '')
                      : setFieldValue('financialEntity', 'DEFAULT'))}
                  />
)}
                label={(
                  <Typography align="left" display="inline" variant="caption">
                    Mi empresa
                    {' '}
                    <Typography
                      variant="caption"
                      display="inline"
                      style={{ fontWeight: 'bold' }}
                    >
                      no tiene convenio con ninguna

                    </Typography>
                    {' '}
                    institución financiera
                  </Typography>
)}
              />
            </FormControl>

          </Form>
          {error
            && <Typography color="error" variant="body2" gutterBottom>Ha ocurrido un error, por favor envíanos un mensaje mediante WhatsApp</Typography>}
        </>
      )}
    </Formik>
  );
}

export default CompanyDataForm;
