import React from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ ownerState, theme }) => ({
    display: 'grid',
    alignItems: 'center',
    fontSize: 20,
    color: theme.palette.white.main,
    ...(ownerState.active && {
    }),
    '& .QontoStepIcon-completedIcon': {
      zIndex: 1,
      fontSize: 20,
    },
    '& .QontoStepIcon-step': {
      color: '#ccc',
    },
    '& .QontoStepIcon-firstStep': {
      fontWeight: 'bold',
      color: theme.palette.tertiary.main,
    },
    '& .QontoStepIcon-secondStep': {
      fontWeight: 'bold',
      color: theme.palette.tertiary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  }),
);

export default function QontoStepIcon(props: StepIconProps) {
  const {
    active, icon, className,
  } = props;
  const iconSwitch = (ic) => {
    switch (ic) {
      case 1:
        return <div className="QontoStepIcon-firstStep">{icon}</div>;
      case 2:
        return <div className="QontoStepIcon-secondStep">{icon}</div>;
      default:
        return icon;
    }
  };
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active ? (
        iconSwitch(icon)
      ) : (
        <div>
          {icon}
        </div>
      )}
    </QontoStepIconRoot>
  );
}
