import React from 'react';
import {
  useLocation,
} from 'react-router-dom';
import JWTDecode from '../../../utils/jwtDecode';
import fetcher from '../../../utils/fetcher';
import { FetchedCompanyReport } from '../../../utils/interfaces/company-report';

import logoWhite from '../../../assets/bancame_logo_white.svg';
import body from '../../../assets/company-report/00_login/body_0.svg';

interface RetrievedCompanyReportData {
  data: FetchedCompanyReport
}

interface payloadInterface{
  firstName:string,
  email: string,
  idType: string,
  idNumber: string,
  userId: string,
  tokenIntention: string,
  role: string,
  exp: number
}

interface LogInInterface{
  handleLogin: (data: string|FetchedCompanyReport) => void,
  handleCounter: (num: number) => void,
}

const useQuery = () => new URLSearchParams(useLocation().search);

function LogIn(props: LogInInterface) {
  const { handleLogin, handleCounter } = props;
  React.useEffect(() => {
    handleCounter(0);
  }, [handleCounter]);
  const [error, setError] = React.useState(false);
  const [code, setCode] = React.useState('');
  const [login, setLogin] = React.useState(false);

  const handleDemo = () => {
    setError(false);
    handleLogin('Demo');
  };
  const query = useQuery();
  const token = query.get('token');

  let payload: payloadInterface|null = null;
  try {
    payload = JWTDecode(token);
  } catch (e) {
    payload = null;
  }
  const handleEnter = async () => {
    setLogin(true);
    try {
      const companyReport = await fetcher(
        `${process.env.REACT_APP_API_URL}/company-report/${code}`,
        'GET',
        null,
        token as string,
      ) as RetrievedCompanyReportData;
      setError(false);
      document.body.style.backgroundColor = '#D7EFF4';
      return handleLogin(companyReport.data);
    } catch (e) {
      setLogin(false);
      return setError(true);
    }
  };
  const [loginState, setLoginState] = React.useState<boolean|string|null>(null);
  React.useEffect(() => { document.body.style.backgroundColor = '#262D40'; }, []);
  React.useEffect(() => {
    if (!payload || payload.tokenIntention !== 'companyReport') {
      return setLoginState(false);
    }
    const now = new Date().getTime();
    const tokenExpDate = new Date(payload.exp * 1000).getTime();
    if (+now > +tokenExpDate) {
      return setLoginState('expired');
    }
    return setLoginState(true);
  }, [payload]);
  const loginSwitch = (state: boolean|string|null) => {
    switch (state) {
      case true:
        return (
          <div key="success">
            {!login ? (
              <>
                <input
                  style={{
                    width: '300px',
                    height: '40px',
                    marginTop: '30px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '30px',
                    borderRadius: '30px',
                  }}
                  type="password"
                  id="lname"
                  name="lname"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <h6 style={{
                  margin: 5,
                  color: error ? '#f44c7f' : undefined,
                }}
                >
                  Por favor ingrese su código de acceso válido

                </h6>

              </>
            ) : (
              <div key="login">
                <div className="loader" />
                <h5 style={{ margin: 3 }}>Cargando...</h5>
              </div>
            )}
            <h3
              onClick={!login ? handleEnter : undefined}
              className="CRlogIn"
              aria-hidden
            >
              ENTRAR

            </h3>
          </div>
        );
      case false:
        return <div key="failed" />;
      case 'expired':
        return (
          <div key="expired">
            <div style={{ fontSize: '25px', margin: '15px' }}>⌛</div>
            <h5 style={{ margin: 3 }}>¡Enlace expirado!</h5>
            <h6 style={{ margin: 3 }}>
              Por favor contáctate con el servicio al cliente de Banca.Me
            </h6>
          </div>
        );
      default:
        return (
          <div key="loading">
            <div className="loader" />
            <h5 style={{ margin: 3 }}>Comprobando enlace</h5>
            <h6 style={{ margin: 3 }}>Un momento por favor...</h6>
          </div>
        );
    }
  };
  return (
    <>
      <img src={body} alt="Background" className="companyReportbackground" />
      <div style={{
        textAlign: 'center',
        display: 'flex',
        height: '100%',
        placeContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
      >
        <div>
          <object data={logoWhite} width="130px" height="130px"> </object>
          <h2>Índice Banca.Me</h2>
          {loginSwitch(loginState)}

          <h3 onClick={handleDemo} className="CRlogIn demo" aria-hidden="true">VER DEMO</h3>
        </div>
      </div>
    </>
  );
}

export default LogIn;
