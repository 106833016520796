import React from 'react';
import CountUp from 'react-countup';
import body1 from '../../../assets/company-report/01_bancame-index/body_1.svg';
import logo from '../../../assets/bancame_logo_dark.svg';
import img1 from '../../../assets/company-report/01_bancame-index/img_1.svg';
import './styles.css';
import { RenderedCompanyReport } from '../../../utils/interfaces/company-report';

interface CRDataInterface {
  reportData: RenderedCompanyReport
}

function CompanyReportBancameIndex(props: CRDataInterface) {
  const { reportData } = props;
  React.useEffect(() => {
    document.body.style.backgroundColor = '#D7EFF4';
    document.body.style.transition = '2s';
  }, []);

  return (
    <div>
      <img src={body1} alt="firstBackground" className="first_background" />

      <div className="first_body">

        <div className="first_header">
          <object data={logo} width="70px" height="70px"> </object>
          <h3
            className="title services"
          >
            Analytics
          </h3>
          <h3 className="title">BIENESTAR FINANCIERO</h3>
        </div>
        <div className="img_1_container">
          <object data={img1} className="img_1"> </object>

        </div>

        <div className="first_content">
          <div />
          <div className="first_subcontent">

            <div style={{
              display: 'flex', height: '120px', alignItems: 'center', placeContent: 'center',
            }}
            >
              <h1 className="bancame_index">
                <CountUp
                  duration={1}
                  end={+reportData.bancameIndex}
                />

              </h1>
            </div>
            <h3 className="subtitle_1">Coeficiente Banca.Me</h3>

            <p className="description">
              Esto representa
              {' '}
              <span style={{ fontWeight: 'bold' }}> el porcentaje </span>
              de trabajadores
              que se encuentran en
              {' '}
              <span style={{ fontWeight: 'bold' }}> situación de Bienestar Financiero</span>
              {' '}
              en tu empresa.
            </p>
            <p className="description">
              Esto significa que
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {Math.round(10 - (+reportData.bancameIndex / 10))}
                {' '}
                de cada 10 trabajadores requieren atención
                {' '}
              </span>
              para mejorar su situación financiera.
            </p>

          </div>
        </div>
      </div>
    </div>

  );
}

export default CompanyReportBancameIndex;
