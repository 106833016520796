import React from 'react';
import body3 from '../../../assets/company-report/07_segmentation/body_5.svg';
import ellipse5 from '../../../assets/company-report/07_segmentation/ellipse_5.svg';
import logo from '../../../assets/bancame_logo_dark.svg';
import img55 from '../../../assets/company-report/07_segmentation/img_55.svg';
import img51 from '../../../assets/company-report/07_segmentation/img_51.svg';
// import img_52 from './img_52.svg';
import img56 from '../../../assets/company-report/07_segmentation/img_56.svg';
import img54 from '../../../assets/company-report/07_segmentation/img_54.svg';
import PartFiveDetail from './detail';
import './styles.css';
import { RenderedCompanyReport } from '../../../utils/interfaces/company-report';

const titleSwitch = (attr: string) => {
  if (attr === 'gender') {
    return (
      <>
        AL
        {' '}
        <span style={{ color: '#35AAC5' }}>GÉNERO DE LAS PERSONAS</span>
      </>
    );
  }
  if (attr === 'costs-center') {
    return (
      <>
        AL
        {' '}
        <span style={{ color: '#35AAC5' }}>CENTRO DE COSTOS</span>
      </>
    );
  }
  if (attr === 'position') {
    return (
      <>
        A LOS
        {' '}
        <span style={{ color: '#35AAC5' }}>INGRESOS DE LOS EMPLEADOS</span>
      </>
    );
  }
  if (attr === 'age') {
    return (
      <>
        A LA
        {' '}
        <span style={{ color: '#35AAC5' }}>EDAD DE LAS PERSONAS</span>
      </>
    );
  }
  return <>A</>;
};

interface CRDataInterface {
  reportData: RenderedCompanyReport
}

function CompanyReportSegmentation(props: CRDataInterface) {
  const { reportData } = props;
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const [statsTitle, setStatsTitle] = React.useState('');
  const [isDetailToggled, setIsDetailToggled] = React.useState(false);
  const scrollRef = React.useRef<null | HTMLDivElement>(null);

  const handleToggleDetail = (title: string) => {
    setIsDetailToggled((o) => !o);
    setStatsTitle(title);
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 700);
  };

  return (
    <>
      <img src={body3} alt="fifthBackground" className="fifth_background" />
      <img src={ellipse5} alt="fifthBackground" className="ellipse_5" />

      <div className="fifth_body">
        <div ref={scrollRef} />
        <div className="fifth_header">
          <object data={logo} width="70px" height="70px"> </object>
          <h2 className="title_5">
            SEPARACIÓN DE INDICADORES DE ACUERDO
            {' '}
            {titleSwitch(statsTitle)}
          </h2>
        </div>
        {!isDetailToggled && (
          <div className="fifth_content">
            <div
              aria-hidden
              className="img_5"
              onClick={() => handleToggleDetail('gender')}
            >
              <object
                data={img51}
                style={{
                  zIndex: -1,
                  pointerEvents: 'none',
                }}
                aria-label="imgGender"
                width="300px"
                height="300px"
              />
              <h4 style={{ margin: 0 }}> Género de las personas</h4>
            </div>
            <div
              aria-hidden
              className="img_5"
              onClick={() => handleToggleDetail('costs-center')}
            >
              <object
                data={img55}
                style={{ zIndex: -1, pointerEvents: 'none' }}
                aria-label="imgEducation"
                width="300px"
                height="300px"
              />
              <h4 style={{ margin: 0 }}>Centro de costos</h4>

            </div>
            <div
              aria-hidden
              className="img_5"
              onClick={() => handleToggleDetail('position')}
            >
              <object
                data={img56}
                style={{ zIndex: -1, pointerEvents: 'none' }}
                aria-label="imgPosition"
                width="300px"
                height="300px"
              />
              <h4 style={{ margin: 0 }}>Sueldos de los empleados</h4>

            </div>
            <div
              aria-hidden
              className="img_5"
              onClick={() => handleToggleDetail('age')}
            >
              <object
                data={img54}
                style={{ zIndex: -1, pointerEvents: 'none' }}
                aria-label="imgAge"
                width="300px"
                height="300px"
              />
              <h4 style={{ margin: 0 }}>Edad</h4>

            </div>
            <div style={{ height: '100px' }} />
          </div>
        )}
        {isDetailToggled && (
          <PartFiveDetail
            handleToggleDetail={handleToggleDetail}
            statsType={statsTitle}
            dataArray={reportData}
          />
        )}

      </div>
    </>
  );
}

export default CompanyReportSegmentation;
