import React from 'react';
import {
  Grid, Typography, useTheme,
} from '@mui/material';

type LoanSimulationProps = {
    amount: number,
    interest: number,
    periods: number,
    installment: number,
    originCost: number,
    createdAt: string
}

export default function LoanSimulation(props: LoanSimulationProps) {
  const {
    amount,
    interest,
    periods,
    installment,
    originCost,
    createdAt,
  } = props;
  const theme = useTheme();
  return (
    <Grid container justifyContent="center" item columnGap={5} sx={{ marginX: { xs: 5, md: 10 } }}>
      <Grid
        item
        xs={11}
      >
        <Typography
          component="div"
          fontWeight="bold"
          width="100%"
          variant="h6"
          textAlign={{ xs: 'center', lg: 'left' }}
        >
          Condiciones del crédito

        </Typography>
        <Typography
          component="div"
          width="100%"
          variant="body2"
          gutterBottom
          fontStyle="italic"
          textAlign={{ xs: 'center', lg: 'left' }}
          marginBottom={5}
          color="text.secondary"
        >
          Fecha simulación:
          {' '}
          {new Date(createdAt).toLocaleDateString('es-CL')}

        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        lg={5}
        textAlign="center"
        padding={{ xs: 3, md: 6 }}
        alignContent="center"
        rowGap={4}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          backgroundColor: 'whitesmoke',
          borderRadius: '15px',
        }}
        border="5px solid whitesmoke"
      >
        <div>
          <Typography color="text.secondary">Monto total</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h4"
            gutterBottom
          >
            $
            {amount.toLocaleString('es-CL')}
          </Typography>
        </div>

        <div>
          <Typography color="text.secondary">Plazo</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h4"
            gutterBottom
          >
            {periods}
            {' '}
            meses
          </Typography>
        </div>
        <div>
          <Typography color="text.secondary">Costo de originación</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h4"
            gutterBottom
          >
            $
            {originCost.toLocaleString('es-CL')}
          </Typography>
        </div>

        <div>
          <Typography color="text.secondary">Tasa de interés anual</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h4"
            gutterBottom
          >
            {interest.toLocaleString(
              'es-CL',
              { style: 'percent', maximumFractionDigits: 2 },
            )}
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        lg={6}
        xs={12}
        justifySelf="center"
        textAlign="center"
        border="5px solid whitesmoke"
        borderRadius="15px"
        padding={3}
        alignContent="center"
        display="grid"
        rowGap={2}
        marginTop={{ xs: 5, lg: 0 }}
      >
        <div>
          <Typography
            fontWeight="bold"
            gutterBottom
          >
            Costo total aproximado del crédito
          </Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h3"

          >
            $
            {(installment * periods).toLocaleString('es-CL')}
          </Typography>
        </div>
        <div>
          <Typography
            fontWeight="bold"
            gutterBottom
          >
            Monto cuota aproximada

          </Typography>
          <Typography
            fontWeight="bold"
            variant="h2"
            style={{ color: theme.palette.primary.main }}
          >
            $
            {installment.toLocaleString('es-CL')}
          </Typography>
        </div>

      </Grid>
    </Grid>
  );
}
