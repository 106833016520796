import React from 'react';
import { Formik, Form } from 'formik';
import {
  Button,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formSchemaInterface } from '../../../../utils/interfaces/simulation';
import simulationStyles from '../../styles';
import { requesterDataValidationSchema } from '../validationSchemas';
import RequesterDataForm from './contactForm';
import FAQ from '../../../../components/FAQ';
import { useIsWidthDown } from '../formLogic';
import BackgroundImage2 from '../../../../assets/background_forms_light_secondary.svg';
import AlertDialog from '../../../../components/AlertDialog';
import ResultCard from './ResultCard';
import ResultTitle from './ResultTitle';

interface ResultsInterface {
  formRef: React.MutableRefObject<any>,
  handleNext: () => void,
  formSchema: formSchemaInterface,
  handleEnableLoading: (state: boolean) => void,
  handleReset: ()=> void,
}

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
  );

  React.useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect(); };
  }, []);

  return isIntersecting;
}

function ContactForm(props: ResultsInterface) {
  const {
    formRef,
    handleNext,
    formSchema,
    handleEnableLoading,
    handleReset,
  } = props;
  const classes = simulationStyles();
  const theme = useTheme();
  React.useEffect(() => {
    window.dataLayer.push({
      event: 'Proceso simulación',
      paso: 'Resultados de simulación',
    });
    handleEnableLoading(false);
  }, []);
  const resultsRef = React.useRef(null);
  const contactFormRef = React.useRef(null);
  const FAQRef = React.useRef(null);
  const resultsIsVisible = useOnScreen(resultsRef);

  const scrollIntoForm = () => {
    if (contactFormRef) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollIntoFAQ = () => {
    if (FAQRef) {
      FAQRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToTop = () => {
    const navBar = document.querySelector('#navBar');
    navBar.scrollIntoView({
      behavior: 'smooth',
    });
  };
  return (
    <Formik
      innerRef={formRef}
      validationSchema={requesterDataValidationSchema}
      initialValues={formSchema}
      onSubmit={() => { window.location.href = 'https://www.banca.me'; }}
    >
      {({
        values,
      }) => (
        <>
          <div
            className={classes.floatingButton}
            style={{
              backgroundColor: theme.palette.secondary.main,
              bottom: 70,
            }}
            onClick={scrollIntoFAQ}
            aria-hidden
          >
            <HelpOutlineIcon style={{
              color: 'white', margin: 'auto', width: '100%', height: '100%',
            }}
            />
          </div>

          <div
            className={classes.floatingButton}
            style={{
              backgroundColor: theme.palette.white.main,
              bottom: useIsWidthDown('md') ? 10 : 130,
              left: useIsWidthDown('md') ? 50 : null,
              border: `2px solid ${theme.palette.primary.main}`,
              display: resultsIsVisible ? 'none' : null,
            }}
            onClick={scrollToTop}
            aria-hidden
          >
            <KeyboardArrowUpIcon style={{
              color: theme.palette.primary.main, margin: 'auto', width: '100%', height: '100%',
            }}
            />
          </div>

          <AlertDialog
            buttonLabel="Volver"
            dialogTitle="¿Volver al inicio?"
            dialogContent="Si continúa, volverá al incio y perderá toda la información de la simulación"
            action={handleReset}
            button={(
              <div
                className={classes.floatingButton}
                style={{
                  backgroundColor: theme.palette.white.main,
                  left: 0,
                  border: `2px solid ${theme.palette.primary.main}`,
                  zIndex: 2,
                }}
                aria-hidden
              >
                <ArrowBackIcon style={{
                  color: theme.palette.primary.main, margin: 'auto', width: '100%', height: '100%',
                }}
                />
              </div>
          )}
          />
          <Form
            className={classes.form}
            style={{
              width: '100%',
              backgroundColor: theme.palette.background.default,
              padding: useIsWidthDown('md') ? theme.spacing(10, 0) : theme.spacing(10),
              borderRadius: '15px',
            }}
          >
            <Typography
              align="center"
              variant="h3"
              sx={{ fontWeight: { xs: 'bold', md: 'normal' } }}
            >
              ¡Gracias por tu tiempo!
            </Typography>
            <Divider />
            <Typography
              align="center"
              variant="h6"
              gutterBottom
              padding={1}
            >
              A continuación te presentamos
              {' '}
              <span style={{ color: theme.palette.primary.main }}>los beneficios</span>
              {' '}
              de tu alianza banca.me

            </Typography>
            <div ref={resultsRef} className={classes.resultsContainer}>
              <div className={classes.resultCardsContainer}>
                <ResultTitle
                  title="Para los trabajadores"
                  subTitle="Con banca.me tus trabajadores estarían ahorrando hasta:"
                />
                <div className={classes.detailGrid}>
                  {+values.simulation.employees.personalSave > 0
                  && (
                    <ResultCard
                      value={`$ ${values.simulation.employees.personalSave.toLocaleString()}`}
                      title="Pesos"
                      subTitle="Anualmente por cada persona que tiene un crédito vigente"
                      customValueStyle={{ alignSelf: Math.abs(values.simulation.employees.personalSave) > 99999 ? 'center' : null }}
                    />
                  )}
                  <ResultCard
                    value={`+ ${values.simulation.employees.days.toLocaleString()}`}
                    title="Días"
                    subTitle="Anualmente dedicados a solicitar un crédito"
                  />
                </div>
              </div>
              <div className={classes.resultCardsContainer}>
                <ResultTitle
                  title="Para la empresa"
                  subTitle="Con banca.me tu empresa estaría ahorrando hasta:"
                />
                <div className={classes.detailGrid}>
                  <ResultCard
                    value={`+ ${values.simulation.employees.totalDaySave.toLocaleString()}`}
                    title="Días"
                    subTitle="Anualmente en trámites para gestionar créditos"
                  />
                  <ResultCard
                    value={`$ ${values.simulation.employees.totalPersonalSave.toLocaleString('es-CL')}`}
                    title="Pesos"
                    subTitle="Anualmente por un incremento de la productividad laboral"
                  />
                </div>
              </div>
            </div>
            <div style={{ margin: '25px auto', textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={scrollIntoForm}
              >
                Quiero que me contacten
              </Button>
            </div>
          </Form>
          <div
            ref={contactFormRef}
            className={classes.contactFormContainer}
            style={{
              backgroundColor: theme.palette.background.default,
              backgroundImage: `url(${BackgroundImage2})`,
              backgroundSize: '100%',
              backgroundPosition: 'left',
            }}
          >
            <RequesterDataForm handleNext={handleNext} formSchema={formSchema} />
          </div>
          <div
            ref={FAQRef}
            className={classes.contactFormContainer}
          >
            <FAQ />
          </div>
        </>

      )}
    </Formik>
  );
}

export default ContactForm;
