import React from 'react';
import {
  Divider,
  Grid, Typography,
} from '@mui/material';
import useStyles from './styles';

type variants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'|'h6'

interface ViewTitleProps{
    title: string,
    subtitle?: string,
    variant?: variants,
    xs?: number,
    md?: number,
    lg?: number
}
const defaultProps = {
  subtitle: '',
  variant: 'h4',
  lg: 12,
  md: 12,
  xs: 12,

};
export default function ViewTitle(props: ViewTitleProps) {
  const {
    title, subtitle, variant, lg, md, xs,
  } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.viewTitle}
      xs={xs}
      md={md}
      lg={lg}
      item
    >
      <div>
        <Typography
          variant={variant}
          fontWeight="bold"
          component="div"
          gutterBottom
        >
          {title}
        </Typography>
        <Divider />
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          align="left"
          gutterBottom
        >
          {subtitle}
        </Typography>
      </div>
    </Grid>
  );
}

ViewTitle.defaultProps = defaultProps;
