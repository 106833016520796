import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import FAQStyles from './styles';
import FAQContent from './content';

export default function FAQ() {
  const classes = FAQStyles();
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h3"
        align="center"
        style={{
          paddingTop: '2.5%',
          marginBottom: '2%',
          fontWeight: 'bold',
          color: theme.palette.white.main,
        }}
      >
        ¿Tienes dudas sobre banca.me?
      </Typography>
      {FAQContent.map((content) => (
        <div className={classes.accordion} key={content.id}>
          <Accordion
            elevation={0}
            square
            style={{
              backgroundColor: theme.palette.background.darkSecondary,
              paddingBottom: 40,
              border: '1px solid white',
              borderWidth: '1px 0px 0px 0px',
            }}
          >
            <AccordionSummary
              expandIcon={(
                <IconButton style={{
                  fontSize: 40,
                  color: theme.palette.tertiary.main,
                }}
                >
                  +
                </IconButton>
)}
              aria-controls="panel1a-content"
              id={`panel1a-header${content.id}`}
            >
              <Typography
                fontSize={20}
                style={{ color: theme.palette.white.main }}
                align="left"
              >
                {content.title}

              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div className={classes.accordionContent}>{content.detail}</div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </>
  );
}
