import React from 'react';
import { Card, Paper, Typography } from '@mui/material';
import type { StatsData } from '../../types/account';
import useStyles from './styles';

type LoanStatsProps = {
    loanStats: StatsData['data']['companyStats']['loanStats']
}
const loanStatsContent = (loanStats: LoanStatsProps['loanStats']) => [
  {
    id: 1,
    label: 'Tasa promedio',
    value: loanStats.interest.toLocaleString('es-CL', { style: 'percent', maximumFractionDigits: 1 }),
  },
  {
    id: 2,
    label: 'Monto promedio',
    value: `$${Math.round(loanStats.amount).toLocaleString('es-CL')}`,
  },
  {
    id: 3,
    label: 'Plazo promedio',
    value: `${loanStats.periods.toLocaleString('es-CL')} meses`,
  },
  {
    id: 4,
    label: 'Monto cuota promedio',
    value: `$${Math.round(loanStats.installments).toLocaleString('es-CL')}`,
  },
];

export default function LoanStats(props: LoanStatsProps) {
  const { loanStats } = props;
  const classes = useStyles();
  return (
    <Paper style={{ backgroundColor: 'whitesmoke', borderRadius: 10 }}>
      <div className={classes.statsHeader}>
        <Typography
          align="center"
          fontWeight="bold"
          color="inherit"
        >
          Estadísticas generales
        </Typography>
      </div>
      <div className={classes.loanStatsContainer}>
        {loanStatsContent(loanStats).map((item) => (
          <Card key={item.id} style={{ textAlign: 'center', padding: 40, borderRadius: 10 }}>
            <Typography align="center" variant="h4" color="secondary" fontWeight="bold">
              {item.value}
            </Typography>
            <Typography align="center" fontWeight="bold">
              {item.label}
            </Typography>
          </Card>
        ))}
      </div>
    </Paper>
  );
}
