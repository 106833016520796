import React from 'react';
import './styles.css';
import { Button, Typography } from '@mui/material';
import notFound from './404.svg';
import useStyles from './styles';
import logo from '../../assets/bancame_logo_dark.svg';

function NotFound() {
  const classes = useStyles();
  const redirectToLanding = () => {
    window.location.href = 'https://banca.me';
  };

  return (

    <div className={classes.root}>
      <div className={classes.content}>
        <object data={logo} className={classes.logo}> </object>
        <Typography
          variant="h5"
          fontWeight="bold"
          gutterBottom
        >
          Empresas
        </Typography>
        <div className={classes.card}>
          <div>
            <img
              src={notFound}
              alt="notFound"
              className={classes.notFound}
            />

          </div>
          <div className={classes.text}>
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
            >
              ¡Nos perdimos!

            </Typography>
            <Typography gutterBottom>
              Esta página no existe
            </Typography>
            <Button
              variant="contained"
              onClick={redirectToLanding}
            >
              Volver a banca.me

            </Button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default NotFound;
