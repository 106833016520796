import React from 'react';
import { useTheme } from '@mui/material';
import type { InvoicesData } from '../../types/account';
import DataTable from '../../components/DataTable';

type InvoicesDataTableProps = {
    companyInvoices: InvoicesData['data']['invoices']
}

export default function InvoicesDataTable(props: InvoicesDataTableProps) {
  const { companyInvoices } = props;
  const theme = useTheme();
  const excelData = companyInvoices.length > 0
    && companyInvoices.map(({ invoice, invoiceDebtsDate }) => {
      const invDebtsDate = new Date(invoiceDebtsDate).toLocaleDateString();
      const created = new Date(invoice.createdAt).toLocaleDateString();
      const updated = new Date(invoice.updatedAt).toLocaleDateString();
      const numChildren = invoice.children.length;
      const delinquency = invoice.hasDelinquency.toLocaleString();
      return {
        type: invoice.type,
        rut: invoice.rut,
        companyName: invoice.name,
        id: invoice.id,
        hasDelinquency: delinquency,
        totalAmount: invoice.totalAmount,
        numChildren,
        created,
        updated,
        invDebtsDate,
      };
    });
  const excelHeaders = [
    'Tipo', 'Rut', 'Nombre', 'Id Boleta', 'Morosidad',
    'Monto Total', 'Boletas de Colaboradores',
    'Fecha Creación', 'Última Actualización', 'Fecha Corte de Cobro',
  ];
  return (
    <DataTable
      tableTitle="Pagos"
      tableHeaders={['Estado',
        'Monto total',
        'Boletas de colaboradores',
        'Fecha corte de cobro',
        'Última actualización',
      ]}
      tableBody={companyInvoices.length > 0
        && companyInvoices.map(({ invoice, invoiceDebtsDate }) => ({
          id: invoice.id,
          content: [invoice.isPaid
            ? <b style={{ color: 'green' }}>PAGADO</b>
            : <b style={{ color: theme.palette.secondary.main }}>POR PAGAR</b>,
          `$${invoice.totalAmount.toLocaleString()}`,
          +invoice.children.length,
          new Date(invoiceDebtsDate).toLocaleDateString(),
          new Date(invoice.updatedAt).toLocaleDateString(),
          ],
        }))}
      excelTitle={companyInvoices.length > 0 && `${companyInvoices[0].invoice.name}_Pagos`}
      excelHeaders={excelHeaders}
      excelData={excelData}
    />
  );
}
