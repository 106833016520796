import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/bancame_logo_dark.svg';
import Header from '../header';
import bancameIndex from './bancame-index/financial-report.png';
import financialWellness from './financial-wellness/financial-wellness.png';

function Enterprises() {
  const history = useHistory();
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const handleProduct = (productSelected: string) => {
    if (productSelected === 'bancameIndex') {
      history.push('/products/enterprises/bancame-index');
    }
    if (productSelected === 'financialWellness') {
      history.push('/products/enterprises/financial-wellness');
    }
  };
  return (
    <Header type="enterprises">
      <div className="cards_container">

        <div
          aria-hidden
          className="card"
          onClick={() => handleProduct('bancameIndex')}
        >
          <div
            aria-hidden
            className="card_image enterprise"
            style={{
              backgroundImage: `url(${bancameIndex})`,
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '70px' }}>&#128202;</span>
              <h2><b>Índice banca.me</b></h2>
            </div>

          </div>
          <div className="container">

            <p>
              Una radiografía rápida y gratuita sobre la
              situación financiera de tus trabajadores

            </p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div
          aria-hidden
          className="card"
          onClick={() => handleProduct('financialWellness')}
        >
          <div
            className="card_image enterprise"
            style={{
              backgroundImage: `url(${financialWellness})`,
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '70px' }}>&#128203;</span>
              <h2><b>Estudio bienestar financiero</b></h2>
            </div>

          </div>
          <div className="container">

            <p>
              Diagnóstico, plan de acción y seguimiento del
              bienestar financiero de tus trabajadores

            </p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>

      </div>

    </Header>
  );
}

export default Enterprises;
