import React from 'react';
import {
  Typography,
  Grid,
  Hidden,
  Stepper,
  Step,
  StepLabel,
  useTheme,
} from '@mui/material';
import WhatsappOutlinedIcon from '@mui/icons-material/WhatsappOutlined';
import bancameLogo from '../../assets/bancame_logo_white.svg';
import QontoConnector from '../../components/QontoStepIcon/QontoConnector';
import QontoStepIcon from '../../components/QontoStepIcon';
import EnterprisesFormParent from './form';
import simulationStyles from './styles';
import {
  formSchema, getInfographics, stepperSteps,
} from './form/formLogic';
import { formSchemaInterface } from '../../utils/interfaces/simulation';
import BackgroundImage from '../../assets/background_forms_light_secondary.svg';

function SimulationIndex() {
  const classes = simulationStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(1);
  const [stepperActiveStep, setStepperActiveStep] = React.useState(0);
  const [currentData, setCurrentData] = React.useState<formSchemaInterface>(formSchema);
  const handleCurrentData = (data) => setCurrentData(data);
  const handleStep = (step) => setActiveStep(step);
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => {
    if (activeStep === -1) {
      window.location.href = 'https://banca.me/';
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const handleReset = () => handleStep(1);
  const handleOpenWhatsApp = () => {
    window.open('https://api.whatsapp.com/send/?phone=56920711980&text=Hola.+Tengo+una+pregunta+con+respecto+al+simulador+de+empresas');
  };
  React.useEffect(() => {
    if (activeStep > 1) {
      return setStepperActiveStep(1);
    }
    if (activeStep === 1) {
      return setStepperActiveStep(0);
    }
    return setStepperActiveStep(0);
  }, [activeStep]);

  return (
    <Grid
      container
      className={classes.root}
    >

      <div
        className={classes.floatingButton}
        style={{
          backgroundColor: 'green',

        }}
        onClick={handleOpenWhatsApp}
        aria-hidden
      >
        <WhatsappOutlinedIcon style={{
          color: 'white', margin: 'auto', width: '100%', height: '100%',
        }}
        />

      </div>

      <Grid
        item
        xs={12}
        className={classes.navBar}
        id="navBar"
      >
        <img
          src={bancameLogo}
          alt="BancaMeLogo"
          className={classes.logo_img}
          aria-hidden="true"
        />
        <div className={classes.stepperContainer}>
          {activeStep < 4
            ? (
              <Stepper
                activeStep={stepperActiveStep}
                connector={<QontoConnector />}
              >
                {stepperSteps.map((label, idx) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                    >
                      {stepperActiveStep === idx
                        ? (
                          <Typography
                            sx={{ fontSize: { xs: 15, md: 20 } }}
                            fontWeight="bold"
                            style={{ color: theme.palette.tertiary.main }}
                          >
                            {label}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ fontSize: { xs: 15, md: 20 } }}
                            fontWeight="bold"
                            style={{ color: theme.palette.gray.main }}
                          >
                            {label}
                            {' '}
                          </Typography>
                        )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <Typography />
            )}
        </div>
      </Grid>
      {(activeStep !== 3 && activeStep !== 2)
        && (
        <Hidden lgDown={activeStep !== 4}>
          <Grid
            item
            xs={activeStep === 4 ? 12 : false}
            lg={activeStep === 4 ? 12 : 6}
            className={classes.leftGrid}
            sx={{
              borderRadius: {
                xs: '15px',
                md: activeStep === 1 ? '15px 0px 0px 15px' : '15px',
              },
            }}
          >

            <div
              className={classes.infographicsContainer}
              style={activeStep === 4 ? {
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              } : undefined}
            >
              {getInfographics(activeStep)}
            </div>

          </Grid>
        </Hidden>
        )}
      {activeStep !== 4
          && (

            <Grid
              item
              xs={12}
              lg={activeStep === 1 ? 6 : 12}
              className={classes.rightGrid}
              style={{
                backgroundColor: activeStep === 3 ? 'white' : null,
                padding: activeStep === 3 ? 0 : null,
              }}
              sx={{ borderRadius: { xs: '15px', md: activeStep === 1 ? '0px 15px 15px 0px' : '15px' } }}
            >
              <EnterprisesFormParent
                activeStep={activeStep}
                currentData={currentData}
                handleCurrentData={handleCurrentData}
                handleStep={handleStep}
                handleNext={handleNext}
                handleBack={handleBack}
                handleReset={handleReset}
              />

            </Grid>

          )}
    </Grid>

  );
}

export default SimulationIndex;
