import React from 'react';
import { Card, Typography } from '@mui/material';
import simulationStyles from '../../styles';

type ResultCardProps ={
    value: string,
    title: string,
    subTitle: string,
    customValueStyle?: any
}

export default function ResultCard(props: ResultCardProps) {
  const {
    value, title, subTitle, customValueStyle,
  } = props;
  const classes = simulationStyles();
  return (
    <Card
      className={classes.resultsCard}
      style={{ borderRadius: 10 }}
    >
      <Typography
        color="secondary"
        variant="h3"
        sx={{ marginLeft: { xs: '-20px', md: '-35px' }, fontSize: { xs: 35, md: 50 } }}
        fontWeight="bold"
        style={customValueStyle}
      >
        {value}
      </Typography>
      <div>
        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {subTitle}
        </Typography>
      </div>
    </Card>
  );
}

ResultCard.defaultProps = {
  customValueStyle: undefined,
};
