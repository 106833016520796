import { Typography, useTheme } from '@mui/material';
import React from 'react';
import simulationStyles from '../../styles';

type ResultTitleProps = {
    title: string,
    subTitle: string
}

export default function ResultTitle(props:ResultTitleProps) {
  const { title, subTitle } = props;
  const theme = useTheme();
  const classes = simulationStyles();
  return (
    <div>
      <div
        className={classes.resultsTitle}
        style={{ backgroundColor: theme.palette.secondary.main }}
      >
        <Typography
          align="center"
          variant="body1"
          fontWeight="bold"
          style={{ color: theme.palette.white.main }}
        >
          <span style={{ paddingLeft: 10 }}>
            {title}
          </span>
        </Typography>
      </div>
      <Typography
        variant="body1"
        style={{ margin: '10px 20px' }}
      >
        {subTitle}
      </Typography>
    </div>
  );
}
