import React from 'react';
import { ReactSession } from 'react-client-session';
import { CircularProgress, Grid, Typography } from '@mui/material';
import isotipoDark from '../../assets/isotipo_dark_secondary.svg';
import jwtDecode from '../../utils/jwtDecode';
import { useQuery } from '../../utils/helpers';

type TokenDecodeProps = {
    successCallback: ()=> void,
    dataLayer?: {}
}

export default function TokenDecode(props:TokenDecodeProps) {
  const { successCallback, dataLayer } = props;
  const query = useQuery();
  const token = query.get('token');
  const { tokenIntention, exp } = jwtDecode(token);
  const [tokenExpired, setTokenExpired] = React.useState(false);

  if (['companyLogin', 'companyLoanApproval'].includes(tokenIntention)
        && (new Date(exp * 1000).getTime() > new Date().getTime())) {
    ReactSession.setStoreType('sessionStorage');
    ReactSession.set('token', token);
    setTimeout(() => successCallback(), 2000);
  }
  React.useEffect(
    () => {
      if (new Date(exp * 1000).getTime() < new Date().getTime()) {
        window.dataLayer.push({
          event: 'Link expirado',
        });
        setTokenExpired(true);
      }
    },
    [exp],
  );
  React.useEffect(() => {
    if (dataLayer) {
      window.dataLayer.push(dataLayer);
    }
  }, [dataLayer]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '85%', margin: 'auto' }}
    >
      <Grid item justifyContent="center" alignItems="center">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <object
            data={isotipoDark}
            width="120px"
            style={{ marginInline: 'auto', marginBottom: '50px' }}
            aria-labelledby="Bancamin"
          />
          {!tokenExpired
                      && <CircularProgress size={50} />}
        </div>
        <Typography
          variant="h4"
          style={{ textAlign: 'center' }}
          fontWeight="bold"
          gutterBottom
        >
          <br />
          {tokenExpired ? '¡El link ha expirado!' : 'Cargando...'}
        </Typography>
        {tokenExpired
                  && (
                  <Typography align="center" variant="h5">
                    Por favor contáctate con nosotros para enviarte uno nuevo si lo deseas
                  </Typography>
                  )}
      </Grid>
    </Grid>
  );
}

TokenDecode.defaultProps = { dataLayer: undefined };
