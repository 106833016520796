import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import AppNavbar from '../../../components/AppNavbar';
import AdminTable from '../../../components/AdminTable';
import type { CompanyAccountComponent } from '../../../types/account';
import { getCompanyLoans } from '../../../queries/account';
import ViewTitle from '../../../components/ViewTitle';

export default function CompanyAccountLoans(props: CompanyAccountComponent) {
  const { token, companyName } = props;
  const { loansData, loansDataLoading, loansDataError } = getCompanyLoans(token);
  return (
    <AppNavbar title={companyName}>
      <>
        <Grid
          container
        >
          <ViewTitle
            title="Créditos activos de tus colaboradores"
            xs={8}
            md={8}
            lg={5}
          />

        </Grid>
        {loansDataError && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
        {!loansDataError && loansDataLoading && <CircularProgress size={50} />}
        {!loansDataError && !loansDataLoading && loansData
          && (
            <AdminTable
              rows={loansData}
              title="Créditos activos"
              noDataText="(Actualmente tus colaboradores no presentan créditos
                activos con Banca.Me)"
            />
          )}
        <div style={{ marginBottom: '100px' }} />
      </>
    </AppNavbar>
  );
}
